/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import { ParseContent } from 'components/shared'
import { SimpleTools } from 'components/elements'

// Media
import arrowRightIcon from 'img/arrow-right.svg'

// Blog
import Blog from 'components/related/Blog'
import BlogIk, { BlogConsumer } from 'components/shared/BlogIk'

const Hero = styled.section`
  //background-image: url(${backImg});

  @media screen and (max-width: 991px) {
    background-size: cover !important;
  }
`

const HeroContent = styled.div`
  padding-top: 200px;
`

const HeroImage = styled(Plaatjie)`
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

  & > img {
    object-position: center !important;
    object-fit: cover !important;
    opacity: 1 !important;
  }

  @media screen and (min-width: 991px) {
    width: 100%;
    height: 540px;
  }

  @media screen and (max-width: 991px) {
    width: 80%;
    height: 240px;
    border-radius: 15px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

export const FeatureContainer = styled.div`
  margin: 15px 0;
`

export const FeatureTitle = styled(ParseContent)`
  & h2 {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.l};
    line-height: ${(props) => props.theme.font.size.xm};
    margin: 0;
  }
`

export const FeatureContent = styled(ParseContent)`
  font-family: ${(props) => props.theme.font.family.secondary};
`

const SolutionContainer = styled.div`
  margin-top: 90px;
  background: white;
  border-radius: 25px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 992px) {
    padding: 10px 100px 0px 100px;
  }

  @media (max-width: 991px) {
    padding: 10px 0.5rem 0px 0.5rem;
  }
`

const SolutionContent = styled(ParseContent)`
  font-family: ${(props) => props.theme.font.family.secondary};
  line-height: ${(props) => props.theme.font.size.l};
`

const SolutionTitle = styled.div`
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.l};
  line-height: ${(props) => props.theme.font.size.xxxl};
  color: ${(props) => props.theme.color.text.main};
  background: white;
  padding: 0 20px;
  position: relative;
  margin-top: -35px;
  width: fit-content;
  margin-bottom: 40px;
`

const SolutionImage = styled.div`
  & > div {
    position: absolute !important;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 992px) {
      position: relative !important;
      margin-top: 30px;
    }
  }
`

const SolutionItemContainer = styled(Link)`
  display: block;

  & span {
    color: ${(props) => props.theme.color.text.main};
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.l};
    line-height: ${(props) => props.theme.font.size.l};
  }

  @media screen and (max-width: 992px) {
    font-size: ${(props) => props.theme.font.size.m};
    line-height: ${(props) => props.theme.font.size.xm};
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero
        className="hero-image"
        aboveFold
        style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`,
        }}
      >
        <HeroContent className="container">
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center justify-content-center pr-lg-5">
              <ParseContent
                className="color-light text-center text-lg-left pr-lg-5"
                content={acf.banner.description}
              />
            </div>
            <div className="col-lg-7 d-flex justify-content-center">
              <HeroImage
                image={acf.banner.image}
                alt={acf.banner.image.title}
              />
            </div>
          </div>
        </HeroContent>
      </Hero>

      <div className="container my-5 pt-lg-5">
        <div className="d-lg-flex justify-content-between mb-5">
          {acf.usps.map(({ icon, title, description }) => {
            return (
              <div key={title}>
                <div className="px-lg-4 mb-5 mb-lg-0">
                  <FeatureContainer>
                    <div className="d-flex align-items-center">
                      <Plaatjie image={icon} alt="feature icon" />
                      <span className="ml-3 my-auto">
                        <FeatureTitle content={`<h2>${title}</h2>`} />
                      </span>
                    </div>
                    <div className="mt-3">
                      <FeatureContent content={description} />
                    </div>
                  </FeatureContainer>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="container">
        <div className="px-lg-5 pb-5">
          <SolutionContainer>
            <SolutionTitle>{acf.block.title}</SolutionTitle>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-lg-6">
                <SolutionContent content={acf.block.description} />
                <SolutionImage>
                  <Plaatjie
                    image={acf.block.image}
                    style={{
                      width: '100%',
                      height: 250,
                      borderRadius: 25,
                      objectFit: 'cover',
                    }}
                    alt={acf.block.image.title}
                  />
                </SolutionImage>
              </div>
              <div className="col-lg-5 pl-lg-2 my-5 my-lg-2">
                {acf.block.links.map(({ link }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SolutionItemContainer
                    key={index}
                    className="mb-4 mb-lg-2 mb-lg-5"
                    to={link.url}
                  >
                    <span>{link.title}</span>
                    <img
                      className="ml-4"
                      src={arrowRightIcon}
                      alt="arrow right"
                    />
                  </SolutionItemContainer>
                ))}
              </div>
            </div>
          </SolutionContainer>
        </div>
      </div>

      <div className="py-lg-5 py-xl-4" />

      <div className="mt-3 mt-lg-5 pt-xl-5">
        <div className="pb-5 py-lg-5">
          <SimpleTools fields={acf.tools} />
        </div>
      </div>

      <div className="container d-flex flex-wrap my-5 pb-5">
        <BlogIk
          settings={{
            limit: 3,
            postIds: acf.blog,
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts }) => {
              return <div>{hasPosts && <Blog posts={posts} />}</div>
            }}
          </BlogConsumer>
        </BlogIk>
      </div>
    </Layout>
  )
}

export default HomeTemplate
